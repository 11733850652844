<template>
  <v-btn
    @click="copy"
    :title="title"
    :icon="!$slots.default"
    :size="size"
    :variant="variant"
  >
    <slot>
      <v-icon :size="iconSize" :color="success ? 'success' : undefined">
        {{ success ? "check" : "content_copy" }}
      </v-icon>
    </slot>
  </v-btn>
</template>

<script lang="ts">
export default defineComponent({
  name: "CopyBtn",
  props: {
    text: { type: String, required: true },
    title: { type: String, default: "Copy" },
    size: { type: [String, Number], default: "small" },
    variant: { type: String as PropType<any>, default: "text" },
  },
  data: () => ({
    success: false,
  }),
  methods: {
    copy() {
      this.success = true;
      navigator.clipboard.writeText(this.text);
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },
  },
  computed: {
    iconSize() {
      return isNumeric(this.size) ? Number(this.size) * 0.8 : this.size;
    },
  },
});
</script>
